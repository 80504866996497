<script setup>
    import { Field, defineRule, configure } from 'vee-validate';
    import { confirmed, email, min, required, length } from '@vee-validate/rules';
    import { localize, setLocale } from '@vee-validate/i18n';
    import en from '@vee-validate/i18n/dist/locale/en.json';
    import fr from '@vee-validate/i18n/dist/locale/fr.json';

    // Load the validator locales
    configure({
        generateMessage: localize({
            en,
            fr,
        }),
    });

    // Initialize i18n
    const { locale } = useI18n();
    setLocale(locale.value);

    // Define the rule globally
    defineRule('confirmed', confirmed);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('required', required);
    defineRule('length', length);

    const props = defineProps({
        id: {
            type: String,
            default: null,
        },
        class: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        overflow: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        root: {
            type: Boolean,
            default: false,
        },
        inputId: {
            type: String,
            default: null,
        },
        pt: {
            type: Object,
            default: {},
        },
    });

    // Retourne l'id du composant passé en props
    // ou génère un slug à partir du nom du champ + l'uid du composant
    const componentId = computed(() => {
        return props.inputId || props.id || slugify(props.name) + '-' + getCurrentInstance().uid;
    });

    const classes = computed(() => {
        let classes = props.class;

        if (!props.root) {
            classes += ' flex flex-1 flex-column gap-1';
        } else {
            classes += ' contents';
        }

        if (!props.overflow) {
            classes += ' overflow-hidden';
        }

        return classes;
    });

    // Label à afficher, avec un astérisque si le champ est requis
    const label = computed(() => {
        let label = props.label || props.name;

        if (props.rules?.indexOf('required') !== -1 && label.indexOf('*') === -1) {
            label += ' *';
        }

        return label;
    });

    const field = ref();

    // Force change field when value changes
    watch(
        () => props.value,
        (value) => {
            field.value.handleChange(value);
        }
    );
</script>

<template>
    <Field
        ref="field"
        :name="props.name?.toLowerCase()"
        :rules="props.rules"
        :value="props.value"
        v-slot="{ handleChange, errorMessage, meta }"
    >
        <label v-if="showLabel" v-bind="props.pt?.label">
            {{ label }}
        </label>

        <div :id="componentId" :class="classes">
            <slot
                :errorClass="errorMessage ? 'p-invalid' : null"
                :classes="{
                    'p-invalid': !!errorMessage,
                    'p-valid': meta.valid,
                }"
                :change="handleChange"
            />

            <small v-if="props.rules && errorMessage" class="p-error w-full">
                {{ props.errorMessage || errorMessage }}
            </small>
        </div>
    </Field>
</template>
